import React, { useState, useEffect } from 'react';
import { useWebProductSearchMutation } from '../../generated/graphql';
import {
  Box,
  Input,
  Text,
  Button,
  SimpleGrid,
  Spinner,
  Flex,
  Container
} from '@chakra-ui/react';
import ProductItem from '../product/ProductSearchItem';
import { Layout } from '../Layout';

export const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const forbiddenSku = [
    '059',
    '061',
    '065',
    '066',
    '067',
    '032',
    '073',
    '072',
    '028',
    '031',
    '025',
    '029',
    '1255',
    '027',
    '033',
    '1256',
    '1257',
    '1260',
    '1253',
    '1254',
    '1252',
    '1548',
    '1265',
    '020',
    '022',
    '1261',
    '1549',
    '1551',
    '1552',
    '1553',
    '1570',
    '1571',
    '1572',
    '1555',
    '1574',
    '1573',
    '1569',
    '1554',
    '1556',
    '1557',
    '1783',
    '1784',
    '1787',
    '1788',
    '1792',
    '1791',
    '015',
    '1258',
    '1259',
    '045',
    '038',
    '043',
    '047',
    '044',
    '1264',
    '1263',
    '1262',
    '057',
    '055',
    '1559',
    '1558',
    '1560',
    '1550',
    '014',
    '017',
    '019',
    '1785',
    '1786',
    '1789',
    '1790',
    '008',
    '018',
    '004',
    '006',
    '009',
    '077',
    '1634',
    '1635',
    '1636',
    '1637',
    '1638',
    '1639',
    '1640',
    '1641',
    '063',
    '149',
    '3001',
    '3002',
    '3003',
    '3004',
    '3006',
    '3007',
    '3009',
    '3011',
    '3012',
    '3013',
    '3014',
    '3017',
    '3018',
    '3019',
    '3021',
    '3022',
    '3024',
    '3025',
    '3011',
    '3107',
    '3108',
    '3111',
    '3114',
    '3115',
    '146',
    '130',
    '114',
    '120',
    '181',
    '183',
    '3000',
    '3005',
    '3008',
    '3010',
    '3014',
    '3015',
    '3016',
    '3020',
    '3023',
    '3026',
    '192',
    '3112',
    '3113',
    '155',
    '3110',
    '3097',
    '3098',
    '151',
    '168',
    '169',
    '141',
    '142',
    '143',
    '112',
    '062',
    '064',
    '170',
    '048',
    '054',
    '053',
    '184',
    '3033',
    '3035',
    '3034',
    '3032',
    '3030',
    '3029',
    '3028',
    '3027',
    '3069',
    '3068',
    '3067',
    '3066',
    '3065',
    '3064',
    '3063',
    '3062',
    '3061',
    '3060',
    '3058',
    '3041',
    '3042',
    '3044',
    '3043',
    '3045',
    '3047',
    '3048',
    '3051',
    '3045',
    '3050',
    '3052',
    '3053',
    '3054',
    '3055',
    '3056',
    '3057',
    '3038',
    '3039',
    '3040',
    '3071',
    '3072',
    '3073',
    '3074',
    '3075',
    '3076',
    '3077',
    '3078',
    '3079',
    '3071',
    '3072',
    '3073',
    '3074',
    '3076',
    '3077',
    '3078',
    '3079',
    '3080',
    '3081',
    '3082',
    '3083',
    '3084',
    '3085',
    '3086',
    '3087',
    '3088',
    '3089',
    '3090',
    '3091',
    '3092',
    '3093',
    '3094',
    '3001',
    '3003',
    '3006',
    '154',
    '150',
    '144',
    '3101',
    '3103',
    '3106',
    '160',
    '122',
    '123',
    '124',
    '125',
    '126',
    '127',
    '128',
    '026',
    '058',
    '051',
    '050',
    '049',
    '052',
    '161'
  ];
  const [products, setProducts]: any = useState([]);
  const [runQuery, { loading, called, data }] = useWebProductSearchMutation();
  const startSearch = async (e: any) => {
    e.preventDefault();

    setSearchTerm(searchTerm.trim().replace(/\s+/g, '-'));

    try {
      const res = await runQuery({
        variables: {
          term: searchTerm
        }
      });
      if (res) {
        console.log('🌵💜🐢', res.data?.webProductSearch);

        setProducts(res.data?.webProductSearch);
      }
    } catch (error) {
      console.log('🌵💜🐢', error);
    }
  };

  useEffect(() => {
    if (called && data) {
      setProducts(data.webProductSearch);
    }
  }, [data]);
  return (
    <Layout>
      <Container maxW={'container.xl'}>
        <Box my={10}>
          <form onSubmit={startSearch}>
            <Flex>
              <Input
                type={'search'}
                placeholder={'Search for products'}
                onChange={(e) => setSearchTerm(e.target.value)}
                size={'lg'}
                rounded="none"
                bgColor={'white'}
              />

              <Button
                variant={'solid'}
                backgroundColor="#862337"
                color={'white'}
                rounded={'none'}
                size="lg"
                disabled={loading}
                type="submit"
              >
                Search
              </Button>
            </Flex>
          </form>
        </Box>
        <Box>{loading && <Spinner />}</Box>
        <Box>
          {products?.data?.length > 0 && (
            <SimpleGrid columns={[1, 2, 3, 4]} spacing={10}>
              {products?.data
                ?.filter((p: any) => !forbiddenSku.includes(p.sku))
                .map((product: any) => {
                  let data: any = {
                    title: product.tr,
                    sku: product.sku,
                    photos: product.photos,
                    category: 'CCB'
                  };
                  console.log(product, 'PRODUCT');
                  return (
                    <div>
                      <ProductItem key={product.id} {...data} />
                    </div>
                  );
                })}
            </SimpleGrid>
          )}
        </Box>
      </Container>
    </Layout>
  );
};
