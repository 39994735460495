import React from 'react';
import { Layout } from '../Layout';
import { useParams } from 'react-router-dom';
import { useWebWholesaleOrderQuery } from '../../generated/graphql';
import {
  Box,
  Button,
  Container,
  Flex,
  GridItem,
  Icon,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import formatMoney from '../../utils/formatMoney';
import RenderMaterials from '../../utils/renderMaterials';
import { getSizeFromSku } from '../../utils/getSizeFromSku';

export const Order = () => {
  const { id } = useParams();

  const { data, loading, error } = useWebWholesaleOrderQuery({
    variables: {
      webWholesaleOrderId: id as string
    }
  });

  let total = 0;
  data?.webWholesaleOrder.products.map((product) => {
    total += product.price.price * product.quantity;
  });
  const currency = data?.webWholesaleOrder.products[0].price.currency.code;
  return (
    <Layout>
      <Container my={8} maxW="container.xl">
        <Box mb={6} background="gray.100" px={6} py={6}>
          <SimpleGrid
            columns={{
              base: 1,
              md: 2
            }}
            gap={6}
          >
            <GridItem>
              <Box>
                <Box display={'inline-block'} color="gray.600">
                  Order ID:
                </Box>
                <Box display={'inline-block'} color="gray.800">
                  {data?.webWholesaleOrder?.conversationId}
                </Box>
              </Box>

              <Box>
                <Box display={'inline-block'} color="gray.600">
                  Order Status:
                </Box>
                <Box display={'inline-block'} color="gray.800">
                  {data?.webWholesaleOrder.orderStatus}
                </Box>
              </Box>

              <Box>
                <Box display={'inline-block'} color="gray.600">
                  Date:
                </Box>
                <Box display={'inline-block'} color="gray.800">
                  {dayjs(data?.webWholesaleOrder?.createdAt).format(
                    'DD MMMM YYYY HH:mm'
                  )}
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Box
                textAlign={{
                  base: 'left',
                  md: 'right'
                }}
              >
                <Box display={'inline-block'} color="gray.600">
                  Shipping Method:
                </Box>
                <Box display={'inline-block'} color="gray.800">
                  <Box>{data?.webWholesaleOrder.shippingMethod} </Box>
                </Box>
              </Box>
              {data?.webWholesaleOrder.shippingNumber && (
                <Box
                  textAlign={{
                    base: 'left',
                    md: 'right'
                  }}
                >
                  <Box display={'inline-block'} color="gray.600">
                    Shipping Number:
                  </Box>
                  <Box display={'inline-block'} color="gray.800">
                    <Box>{data?.webWholesaleOrder.shippingNumber} </Box>
                  </Box>
                </Box>
              )}
            </GridItem>
          </SimpleGrid>
        </Box>

        <Box mb={6}>
          <SimpleGrid
            columns={{
              base: 1,
              md: 2
            }}
            gap={6}
          >
            <Box background="gray.100" px={6} py={6}>
              <GridItem colSpan={2}>
                <Box fontWeight={'semibold'} fontSize="lg">
                  Company Information
                </Box>
              </GridItem>
              <GridItem>
                <Box>
                  <Box display={'inline-block'} color="gray.600">
                    Company:
                  </Box>
                  <Box display={'inline-block'} color="gray.800">
                    {data?.webWholesaleOrder?.store.titleEn}
                  </Box>
                </Box>
              </GridItem>

              <GridItem>
                <Box>
                  <Box display={'inline-block'} color="gray.600">
                    Paid by:
                  </Box>
                  <Box display={'inline-block'} color="gray.800">
                    {data?.webWholesaleOrder?.store.paymentFirstName}{' '}
                    {data?.webWholesaleOrder?.store.paymentLastName}
                  </Box>
                </Box>
              </GridItem>

              <GridItem>
                <Box>
                  <Box display={'inline-block'} color="gray.600">
                    Phone Number:
                  </Box>
                  <Box display={'inline-block'} color="gray.800">
                    {data?.webWholesaleOrder?.store.paymentPhoneNumber}
                  </Box>
                </Box>
              </GridItem>

              <GridItem>
                <Box>
                  <Box display={'inline-block'} color="gray.600">
                    E-Mail:
                  </Box>
                  <Box display={'inline-block'} color="gray.800">
                    {data?.webWholesaleOrder?.store.paymentEmail}
                  </Box>
                </Box>
              </GridItem>

              <GridItem colSpan={2}>
                <Box>
                  <Box display={'inline-block'} color="gray.600">
                    Address:
                  </Box>
                  <Box display={'inline-block'} color="gray.800">
                    {data?.webWholesaleOrder?.store.paymentAddress}{' '}
                    {data?.webWholesaleOrder?.store.paymentPostalCode}{' '}
                    {data?.webWholesaleOrder?.store.paymentCity}{' '}
                    {data?.webWholesaleOrder?.store.paymentCountry}
                  </Box>
                </Box>
              </GridItem>

              <GridItem>
                <Box>
                  <Box display={'inline-block'} color="gray.600">
                    Tax Information:
                  </Box>
                  <Box display={'inline-block'} color="gray.800">
                    {data?.webWholesaleOrder?.store.paymentTaxOffice}{' '}
                    {data?.webWholesaleOrder?.store.paymentTaxNumber}
                  </Box>
                </Box>
              </GridItem>
            </Box>

            <Box background="gray.100" px={6} py={6}>
              <GridItem colSpan={2}>
                <Box fontWeight={'semibold'} fontSize="lg">
                  Shipping Information
                </Box>
              </GridItem>

              <GridItem>
                <Box>
                  <Box display={'inline-block'} color="gray.600">
                    Phone Number:
                  </Box>
                  <Box display={'inline-block'} color="gray.800">
                    {data?.webWholesaleOrder?.store.shippingPhoneNumber}
                  </Box>
                </Box>
              </GridItem>

              <GridItem>
                <Box>
                  <Box display={'inline-block'} color="gray.600">
                    E-Mail:
                  </Box>
                  <Box display={'inline-block'} color="gray.800">
                    {data?.webWholesaleOrder?.store.shippingEmail}
                  </Box>
                </Box>
              </GridItem>

              <GridItem colSpan={2}>
                <Box>
                  <Box display={'inline-block'} color="gray.600">
                    Address:
                  </Box>
                  <Box display={'inline-block'} color="gray.800">
                    {data?.webWholesaleOrder?.store.shippingAddress}{' '}
                    {data?.webWholesaleOrder?.store.shippingPostalCode}{' '}
                    {data?.webWholesaleOrder?.store.shippingCity}{' '}
                    {data?.webWholesaleOrder?.store.shippingCountry}
                  </Box>
                </Box>
              </GridItem>
            </Box>
          </SimpleGrid>
        </Box>

        <Box
          display={{
            base: 'block',
            md: 'none'
          }}
          mb={6}
          backgroundColor="gray.100"
        >
          {loading ? (
            <Spinner />
          ) : (
            data?.webWholesaleOrder.products.map((product) => {
              return (
                <Box
                  key={product.id}
                  p={4}
                  borderBottom="1px solid"
                  borderColor={'gray.300'}
                >
                  <Flex
                    alignItems={'center'}
                    justifyContent="space-between"
                    mb={4}
                  >
                    <Box>
                      <Box color="gray.400" fontSize={'sm'}>
                        {product.product.barcode}
                      </Box>
                      <Flex alignItems={'center'} fontSize={'lg'}>
                        {
                          product?.product?.productGroup?.description.find(
                            (e: any) => e.language === 'en'
                          )?.title
                        }{' '}
                        <Box ml={1} as="span" fontSize={'sm'}>
                          {' '}
                          (
                          {product.ringSize
                            ? product.ringSize
                            : getSizeFromSku(product.product.sku)}
                          )
                        </Box>
                      </Flex>
                    </Box>
                    <Flex alignItems={'center'}>
                      <Box textAlign={'right'} fontWeight="bold" ml={4}>
                        {formatMoney(
                          product.price.price,
                          product.price.currency.code
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Box mr={4}>
                      <img
                        src={`https://images.jujumood.com/${product.product.productGroup.photos[0]}?format=webp&trim=25&fit=contain&bg=ffffff&width=150`}
                        width="150"
                        height={'150'}
                        alt={product.product.barcode}
                      />
                    </Box>
                    <Box>
                      <RenderMaterials
                        locale={'en'}
                        sku={product.product.sku as string}
                      />
                    </Box>
                  </Flex>
                  {product.additionalInfo && (
                    <Box my={2} color="gray.400">
                      {product.additionalInfo}
                    </Box>
                  )}
                </Box>
              );
            })
          )}
          <Box p={4}>
            <Flex alignItems={'center'}>
              <Box width={'100px'} fontWeight={'semibold'}>
                Total
              </Box>
              <Box textAlign={'right'}>
                {formatMoney(
                  total || 0,
                  data?.webWholesaleOrder.payment[0]?.currency || 'USD'
                )}
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box
          display={{
            base: 'none',
            md: 'block'
          }}
          mb={6}
          backgroundColor="white"
        >
          <Table variant={'simple'} colorScheme="blackAlpha" size={'lg'}>
            <Thead>
              <Tr backgroundColor="#862337">
                <Th color="white" width={'190px'}>
                  Image
                </Th>
                <Th color="white">Product</Th>
                <Th color="white">Size</Th>
                <Th color="white" textAlign={'center'}>
                  Options
                </Th>
                <Th color="white" width={'200px'}>
                  Price
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Spinner />
              ) : (
                data?.webWholesaleOrder.products.map((product) => {
                  return (
                    <Tr key={product.id}>
                      <Td>
                        <img
                          src={`https://images.jujumood.com/${product.product.productGroup.photos[0]}?format=webp&width=150&height=150`}
                          width="150"
                          height={'150'}
                          alt={product.product.barcode}
                        />
                      </Td>
                      <Td>
                        <Box color="gray.400">{product.product.barcode}</Box>
                        <Box>
                          {
                            product?.product?.productGroup?.description.find(
                              (e: any) => e.language === 'en'
                            )?.title
                          }
                        </Box>

                        {product.additionalInfo && (
                          <Box color="gray.400">{product.additionalInfo}</Box>
                        )}
                      </Td>
                      <Td>
                        {product.ringSize
                          ? product.ringSize
                          : getSizeFromSku(product.product.sku)}
                      </Td>
                      <Td>
                        <Box w={'50%'} mx={'auto'}>
                          <RenderMaterials
                            locale={'en'}
                            sku={product.product.sku as string}
                          />
                        </Box>
                      </Td>
                      <Td textAlign={'left'}>
                        Single:{' '}
                        {formatMoney(
                          product.price.price,
                          product.price.currency.code || 'TRY'
                        )}
                        <br />
                        Total:{' '}
                        {formatMoney(
                          product.price.price * product.quantity,
                          product.price.currency.code || 'TRY'
                        )}
                      </Td>
                    </Tr>
                  );
                })
              )}
              <Tr>
                <Td colSpan={4} textAlign="right" fontWeight={'semibold'}>
                  Shipping:
                </Td>
                <Td textAlign={'left'}>
                  {data?.webWholesaleOrder.shippingPrice
                    ? formatMoney(
                        data?.webWholesaleOrder.shippingPrice.price || 0,
                        data?.webWholesaleOrder.shippingPrice.currency.code
                      )
                    : 'Contact Juju Office'}
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={4} textAlign="right" fontWeight={'semibold'}>
                  Total:
                </Td>
                <Td textAlign={'left'}>
                  {total
                    ? formatMoney(total || 0, currency as string)
                    : 'Contact Juju Office'}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};
