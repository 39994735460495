import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  Input,
  Button,
  Box,
  Flex,
  Container,
  Spinner,
  SimpleGrid
} from '@chakra-ui/react';
import { useCategoriesQuery } from '../../generated/graphql';
import 'react-image-lightbox/style.css';
import PageHeading from '../ui/Heading';
import { Link } from 'react-router-dom';

export const Dashboard = () => {
  const { data, loading, error } = useCategoriesQuery({
    variables: {
      showOnPartner: true
    }
  });

  if (error) {
    return <Box>{error.message} abc</Box>;
  }
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Select a Category" />
        {loading ? (
          <Box>
            <Spinner />
          </Box>
        ) : (
          <Box>
            <SimpleGrid
              columns={{
                base: 2,
                md: 4
              }}
              gap={6}
            >
              {data &&
                data.categories
                  .filter((f) => f.sku !== 'GOLD')
                  .map((c) => {
                    const descriptions = c.description.find(
                      (l) => l.language === 'en'
                    );
                    if (c.sku !== 'GIFT' && c.sku !== 'KMB') {
                      return (
                        <Box
                          _hover={{ borderColor: 'green.600' }}
                          key={c.id}
                          border="1px solid"
                          borderColor="gray.300"
                        >
                          <Link to={`/category/${c.sku.toLowerCase()}`}>
                            <Box p={6}>
                              <Box mb={6}>
                                <img
                                  src={`https://images.jujumood.com/${c.photos[0]}?format=webp&width=150&height=150&trim=20&fit=contain&bg=ffffff`}
                                  title={c.sku}
                                  alt={c.sku}
                                  style={{ margin: 'auto' }}
                                />
                              </Box>
                              <Box textAlign={'center'}>
                                <Box fontSize="2xl" fontWeight={'bold'}>
                                  {descriptions?.title}
                                </Box>
                                {/* <Box fontSize={'sm'} color="gray.400">
                                {c.sku}
                              </Box> */}
                              </Box>
                            </Box>
                          </Link>
                        </Box>
                      );
                    }
                  })}
            </SimpleGrid>
          </Box>
        )}
      </Container>
    </Layout>
  );
};
