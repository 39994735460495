import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  Input,
  Button,
  Box,
  Flex,
  FormControl,
  Container,
  Spinner,
  SimpleGrid,
  GridItem,
  FormLabel,
  Textarea,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast
} from '@chakra-ui/react';
import RenderMaterials from '../../utils/renderMaterials';
import 'react-image-lightbox/style.css';
import PageHeading from '../ui/Heading';
import { Link } from 'react-router-dom';
import {
  useWebWholesaleBasketQuery,
  useCreateWholesaleCheckoutFormMutation,
  useCreateWholesaleOrderMutation,
  useWebUpdateWholesaleBasketItemMutation
} from '../../generated/graphql';
import { getSizeFromSku } from '../../utils/getSizeFromSku';
import formatMoney from '../../utils/formatMoney';

export const Payment = () => {
  const toast = useToast();
  const [createCheckoutForm] = useCreateWholesaleCheckoutFormMutation();
  const [createOrder] = useCreateWholesaleOrderMutation();
  const { data, loading, error, refetch } = useWebWholesaleBasketQuery({
    variables: {
      currency: localStorage.getItem('currency') as string,
      storeId: localStorage.getItem('store') as string
    }
  });
  const [updateQuantityItem] = useWebUpdateWholesaleBasketItemMutation();

  // useEffect(() => {
  //   createCheckoutForm({
  //     variables: {
  //       currency: localStorage.getItem('currency') as string,
  //       storeId: localStorage.getItem('store') as string
  //     }
  //   })
  //     .then((r) => {
  //       console.log('kaçkez');
  //       const script = document.createElement('script');

  //       if (r.data) {
  //         let textForm = r.data.createWholesaleCheckoutForm!;
  //         textForm = textForm.replace(`<script type="text/javascript">`, '');
  //         textForm = textForm.replace(`</script>`, '');
  //         textForm = textForm.replace(
  //           `<div id="iyzipay-checkout-form" class="responsive"></div>`,
  //           ''
  //         );

  //         script.innerHTML = textForm;
  //         script.async = true;
  //         document.body.appendChild(script);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  const currency = localStorage.getItem('currency');

  let totalPrice = 0;

  // if (error) {
  //   return (
  //     <Layout>
  //       <Container maxW="container.xl">
  //         An error occured. Please try again in a few minutes.
  //       </Container>
  //     </Layout>
  //   );
  // }
  console.log('🌵💜🐢', data);
  const updateQuantity = async (id: string, quantity: number) => {
    try {
      const update = await updateQuantityItem({
        variables: {
          id: id,
          quantity: quantity
        }
      });
      if (update.data?.webUpdateWholesaleBasketItem.id) {
        toast({
          title: 'Quantity updated',
          status: 'success',
          duration: 3000,
          isClosable: true
        });
        refetch();
      }
    } catch (error) {
      console.log('🌵💜🐢 error', error);
      toast({
        title: 'Error',
        description: "Couldn't update quantity",
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };
  const handleCreateOrder = async () => {
    try {
      await createOrder({
        variables: {
          currency: localStorage.getItem('currency') as string,
          storeId: localStorage.getItem('store') as string
        }
      });
      toast({
        title: 'Order created',
        description: 'Your order has been created',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
      refetch();
    } catch (error) {
      console.log('🌵💜🐢 error', error);
      toast({
        title: 'Error',
        description: 'An error occured. Please try again in a few minutes.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  return (
    <Layout>
      <Container maxW="container.xl">
        {loading ? (
          <Spinner />
        ) : (
          <Box>
            <SimpleGrid columns={1} gap={6}>
              <GridItem>
                <PageHeading title="Cart" />
                <Box id="printArea">
                  {data?.webWholesaleBasket === null && (
                    <Box>There are no products in your shopping cart </Box>
                  )}
                  {data?.webWholesaleBasket?.items.length === 0 && (
                    <Box>There are no products in your shopping cart </Box>
                  )}
                  {data?.webWholesaleBasket?.items.map((b) => {
                    let productPhoto = b.product.productGroup.photos[0];
                    if (b.product.photos.length > 0) {
                      productPhoto = b.product.photos[0];
                    }
                    let descriptions = b.product.productGroup.description.find(
                      (l) => l.language === 'en'
                    );
                    let price = b.product.productGroup.price[0].price;
                    let totalItemPrice = price;

                    const size = getSizeFromSku(b.product.sku);
                    const sizePrice = b.product.productGroup.sizePrices.filter(
                      (p) => p.size.sku === size
                    );

                    if (b.product.productGroup.category.sku === 'STAND') {
                      price = price + (sizePrice[0]?.prices[0]?.price || 0);

                      totalItemPrice = Number(b.quantity) * price;
                      totalPrice = totalPrice + price;
                    } else {
                      //price = price + (sizePrice[0]?.prices[0]?.price || 0);
                      price = Math.ceil(
                        (price + (sizePrice[0]?.prices[0]?.price || 0)) *
                          (currency === 'TL'
                            ? 0.5
                            : currency === 'TRY'
                            ? 0.5
                            : 1)
                      );

                      totalItemPrice =
                        Number(b.quantity) *
                        Math.ceil(
                          price + (sizePrice[0]?.prices[0]?.price || 0)
                        ) *
                        (currency === 'TL'
                          ? 0.5
                          : currency === 'TRY'
                          ? 0.5
                          : 1);

                      console.log(totalPrice, 'TOTAL PRIJ');
                      totalPrice = totalPrice + price;
                    }
                    return (
                      <Box key={b.id} bgColor="gray.100" mb={1} p={4}>
                        <SimpleGrid
                          columns={{
                            base: 1,
                            md: 10
                          }}
                          gap={2}
                        >
                          <GridItem colSpan={1}>
                            <img
                              src={`https://images.jujumood.com/${productPhoto}?extend=10&width=150&bg=ffffff&fit=contain&height=150&trim=20`}
                              alt={'Juju'}
                            />
                          </GridItem>
                          <GridItem colSpan={5}>
                            <Flex
                              mx={3}
                              alignItems={{
                                base: 'flex-start',
                                md: 'center'
                              }}
                            >
                              <Box fontSize={'sm'}>
                                <Text fontSize={'xl'}>
                                  {descriptions?.title}
                                </Text>
                                {b.product.sku}
                              </Box>
                            </Flex>
                            <Box mx={3} my={2}>
                              <RenderMaterials
                                sku={b.product.sku as string}
                                locale="en"
                              />
                            </Box>
                          </GridItem>
                          <GridItem colSpan={2}>
                            <NumberInput
                              onChange={(e) => updateQuantity(b.id, Number(e))}
                              defaultValue={1}
                              value={b.quantity as number}
                              min={1}
                              max={20}
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </GridItem>
                          <GridItem colSpan={2}>
                            <Box textAlign={'right'}>
                              Price:{' '}
                              {formatMoney(
                                price,
                                currency === 'TL' ? 'TRY' : (currency as string)
                              )}
                            </Box>
                            <Box textAlign={'right'}>
                              Total Price:{' '}
                              {formatMoney(
                                (b?.quantity || 1) * price,
                                currency === 'TL' ? 'TRY' : (currency as string)
                              )}
                            </Box>
                          </GridItem>
                        </SimpleGrid>
                      </Box>
                    );
                  })}
                </Box>
              </GridItem>
            </SimpleGrid>
            {data?.webWholesaleBasket !== null && (
              <Button onClick={(e) => handleCreateOrder()}>Checkout</Button>
            )}
          </Box>
        )}
      </Container>
    </Layout>
  );
};
