import { ColorModeScript } from '@chakra-ui/react';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import AppRouter from './router/index';
import './styles/style.css';
const container: any = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <AppRouter />
    <ColorModeScript />
  </React.StrictMode>
);
