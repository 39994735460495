import { Box, Spinner } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, A11y, Pagination } from 'swiper';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGetWholesalePriceQuery } from './../../generated/graphql';
import formatMoney from '../../utils/formatMoney';

interface Props {
  photos?: string[] | string;
  title: string;
  sku: string;
  categorySku: string;
}
const ProductItem: React.FC<Props> = ({ categorySku, sku, title, photos }) => {
  const { data, loading, error } = useGetWholesalePriceQuery({
    variables: {
      currency: localStorage.getItem('currency') as string,
      store: localStorage.getItem('store') as string,
      sku
    }
  });

  const currency = localStorage.getItem('currency');

  let extend = 0;
  if (
    [
      'CCE',
      'CES',
      'CEM',
      'EARRING',
      'RINGS',
      'CCR',
      'OCP',
      'CRS',
      'CRM',
      'OCC',
      'OCR',
      'OCE',
      'CCL',
      'CLM',
      'CCP',
      'CPM',
      'STAND'
    ].includes(categorySku)
  ) {
    extend = 0;
  }
  let trim = 25;
  if (['STAND'].includes(categorySku)) {
    trim = 0;
  }
  return (
    <Box
      _hover={{ borderColor: 'green.600' }}
      border="1px solid"
      borderColor="gray.300"
    >
      <Link to={`/product/${sku.toLowerCase()}`}>
        <Box p={6}>
          <Box mb={6}>
            {typeof photos === 'string' ? (
              <img
                src={`https://images.jujumood.com/${photos}?format=webp&width=363&height=363&quality=75&trim=${trim}&fit=contain&bg=ffffff&extend=30,${extend},30,${extend}`}
                alt={`${sku}`}
              />
            ) : (
              photos &&
              photos.length > 0 && (
                <Swiper
                  modules={[Scrollbar, Pagination, A11y]}
                  slidesPerView={1}
                  pagination
                >
                  {photos.map((p, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <img
                          src={`https://images.jujumood.com/${p}?format=webp&width=363&height=363&quality=75&trim=${trim}&fit=contain&bg=ffffff&extend=30,${extend},30,${extend}`}
                          alt={`${i}-${sku}`}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )
            )}
          </Box>
          <Box textAlign={'center'}>
            <Box fontSize="lg" fontWeight={'bold'}>
              {title}
            </Box>
            <Box fontSize={'sm'} color="gray.400">
              {categorySku}-{sku}
            </Box>
            <Box fontSize={'lg'} color="gray.800" fontWeight={'bold'}>
              {loading ? (
                <Spinner />
              ) : error ? (
                <Box>ASK FOR PRICE</Box>
              ) : (
                <Box>
                  {categorySku === 'STAND'
                    ? formatMoney(
                        Math.ceil(
                          currency === 'USD'
                            ? Number(data?.getWholesalePrice.price) * 1.1
                            : data?.getWholesalePrice.price || 0
                        ),
                        currency === 'USD'
                          ? 'USD'
                          : data?.getWholesalePrice.currency || ''
                      )
                    : formatMoney(
                        Math.ceil(
                          (data?.getWholesalePrice.price || 0) *
                            (currency === 'TRY'
                              ? 0.5
                              : currency == 'USD'
                              ? 1.1
                              : 1)
                        ),
                        currency === 'USD'
                          ? 'USD'
                          : data?.getWholesalePrice.currency || ''
                      )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default ProductItem;
