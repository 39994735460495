import React from 'react';
import { Box, Button, Flex, Icon } from '@chakra-ui/react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
interface Props {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
}
const Pagination: React.FC<Props> = ({ page, setPage, totalPages }) => {
  if (totalPages === 1) {
    return <div></div>;
  }
  return (
    <Box my={6}>
      <Flex justifyContent={'center'} experimental_spaceX={4}>
        <Button
          disabled={page === 1}
          onClick={() => {
            setPage(1);
          }}
        >
          <Icon as={BsArrowLeft} />
        </Button>

        <Button
          disabled={page === 1}
          onClick={() => {
            setPage(page - 1);
          }}
        >
          geri
        </Button>
        <Button
          disabled={page === totalPages}
          onClick={() => {
            setPage(page + 1);
          }}
        >
          ileri
        </Button>

        <Button
          disabled={page === totalPages}
          onClick={() => {
            setPage(totalPages);
          }}
        >
          <Icon as={BsArrowRight} />
        </Button>
      </Flex>
      <Flex justifyContent={'center'} mt={4}>
        <Box
          as="nav"
          position={'relative'}
          zIndex={0}
          display="inline-flex"
          experimental_spaceX={'-1px'}
          shadow="sm"
          aria-label="Pagination"
        >
          <Flex
            as="nav"
            position="relative"
            zIndex={0}
            flexWrap="wrap"
            justifyContent={'center'}
            fontSize="sm"
            aria-label="Pagination"
          >
            {Array.from(Array(totalPages).keys()).map((a) => (
              <Button
                key={a}
                onClick={() => {
                  setPage(a + 1);
                }}
                position="relative"
                mb={2}
                display="inline-flex"
                justifyContent={'center'}
                alignItems="center"
                px={3}
                py={1}
                mr={1}
                backgroundColor={page === a + 1 ? 'green.300' : 'gray.300'}
              >
                {a + 1}
              </Button>
            ))}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Pagination;
