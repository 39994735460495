import React, { useState } from 'react';
import {
  useWebWholesaleBasketCountQuery,
  useWebWholesaleBasketQuery,
  useWebDeleteWholesaleBasketItemMutation,
  useWebUpdateWholesaleBasketItemMutation
} from '../../generated/graphql';
import RenderMaterials from '../../utils/renderMaterials';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Icon,
  Flex,
  Spinner,
  SimpleGrid,
  GridItem,
  Text,
  useToast
} from '@chakra-ui/react';
import { getSizeFromSku } from '../../utils/getSizeFromSku';
import { FaShoppingCart } from 'react-icons/fa';
import formatMoney from '../../utils/formatMoney';
import { Link } from 'react-router-dom';
import { BsFillTrashFill } from 'react-icons/bs';
const Basket = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { data, loading, error } = useWebWholesaleBasketCountQuery({
    variables: {
      storeId: localStorage.getItem('store') as string
    }
  });

  const {
    data: basketData,
    loading: basketLoading,
    error: basketError,
    refetch
  } = useWebWholesaleBasketQuery({
    variables: {
      currency: localStorage.getItem('currency') as string,
      storeId: localStorage.getItem('store') as string
    }
  });

  const [deleteBasketItem] = useWebDeleteWholesaleBasketItemMutation();
  const handleDelete = (id: string) => {
    deleteBasketItem({
      variables: {
        webDeleteWholesaleBasketItemId: id
      }
    });
    refetch();
  };

  let totalPrice = 0;
  const currency = localStorage.getItem('currency');

  return (
    <React.Fragment>
      <Button variant={'link'} onClick={onOpen}>
        <Flex alignItems={'center'}>
          <Icon as={FaShoppingCart} color="white" fontSize={'xl'} />
          <Box fontSize="xs" color="white" ml={2}>
            {loading ? (
              <Spinner />
            ) : error ? (
              'Error'
            ) : (
              data?.webWholesaleBasketCount.result
            )}
          </Box>
        </Flex>
      </Button>
      <Drawer size={'lg'} isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Shopping Cart{' '}
            {loading ? (
              <Spinner />
            ) : error ? (
              'Error'
            ) : (
              `${data?.webWholesaleBasketCount.result} product(s)`
            )}
          </DrawerHeader>

          <DrawerBody>
            {basketError && <Box>{basketError.message}</Box>}
            {basketData?.webWholesaleBasket === null && (
              <Box>There are no products in your shopping cart </Box>
            )}
            {basketData?.webWholesaleBasket?.items.length === 0 && (
              <Box>There are no products in your shopping cart </Box>
            )}
            {basketLoading ? (
              <Box>
                <Spinner />
              </Box>
            ) : (
              basketData?.webWholesaleBasket?.items.map((b) => {
                let productPhoto = b.product.productGroup.photos[0];
                if (b.product.photos.length > 0) {
                  productPhoto = b.product.photos[0];
                }
                let descriptions = b.product.productGroup.description.find(
                  (l) => l.language === 'en'
                );
                let price = b.product.productGroup.price[0].price;
                let itemPrice = price;
                const size = getSizeFromSku(b.product.sku);
                const sizePrice = b.product.productGroup.sizePrices.filter(
                  (p) => p.size.sku === size
                );

                if (b.product.productGroup.category.sku === 'STAND') {
                  itemPrice = Math.ceil(
                    price + (sizePrice[0]?.prices[0]?.price || 0)
                  );
                  price = Number(b?.quantity) * itemPrice;
                } else {
                  itemPrice = Math.ceil(
                    (price + (sizePrice[0]?.prices[0]?.price || 0)) *
                      (currency === 'TL'
                        ? 1
                        : currency === 'TRY'
                        ? 0.5
                        : currency === 'USD'
                        ? 1.1
                        : 1)
                  );
                  price =
                    Number(b?.quantity) *
                    Math.ceil(
                      (price + (sizePrice[0]?.prices[0]?.price || 0)) *
                        (currency === 'TL'
                          ? 1
                          : currency === 'TRY'
                          ? 0.5
                          : currency === 'USD'
                          ? 1.1
                          : 1)
                    );
                }

                totalPrice = totalPrice + price;

                return (
                  <Box key={b.id} bgColor="gray.100" mb={1} p={4}>
                    <SimpleGrid columns={10} gap={2}>
                      <GridItem colSpan={1}>
                        <img
                          src={`https://images.jujumood.com/${productPhoto}?extend=10&width=150&bg=ffffff&fit=contain&height=150&trim=20`}
                          alt={'Juju'}
                        />
                      </GridItem>
                      <GridItem colSpan={4}>
                        <Flex alignItems={'center'}>
                          <Box fontSize={'sm'}>
                            {descriptions?.title} <br />
                            {b.product.sku}
                          </Box>
                        </Flex>
                        <Box my={2}>
                          <RenderMaterials
                            locale="en"
                            size="xs"
                            sku={b.product.sku as string}
                          />
                        </Box>
                      </GridItem>
                      <GridItem colSpan={2}>
                        <Box>
                          <Box fontSize={'sm'}>Size: {size}</Box>
                          <Box fontSize={'sm'}>Quantity: {b.quantity}</Box>
                        </Box>
                      </GridItem>

                      <GridItem colSpan={3}>
                        <Flex alignItems={'center'}>
                          <Box>
                            <Box fontSize={'sm'} textAlign={'right'}>
                              {formatMoney(
                                itemPrice,
                                currency === 'USD'
                                  ? 'USD'
                                  : currency === 'TL'
                                  ? 'TRY'
                                  : b.product.productGroup.price[0].currency
                                      .code
                              )}{' '}
                              per QTY.
                            </Box>

                            <Box fontSize={'sm'} textAlign={'right'}>
                              {formatMoney(
                                price,
                                currency === 'USD'
                                  ? 'USD'
                                  : currency === 'TL'
                                  ? 'TRY'
                                  : b.product.productGroup.price[0].currency
                                      .code
                              )}
                            </Box>
                          </Box>
                          <Flex ml={4}>
                            <Button
                              size={'xs'}
                              variant={'solid'}
                              colorScheme={'red'}
                              onClick={() => handleDelete(b.id)}
                            >
                              <Icon as={BsFillTrashFill} color="white" />
                            </Button>
                          </Flex>
                        </Flex>
                      </GridItem>
                    </SimpleGrid>
                  </Box>
                );
              })
            )}
            <Box textAlign={'right'} p={4}>
              {formatMoney(
                totalPrice,
                localStorage.getItem('currency') === 'TL'
                  ? 'TRY'
                  : (localStorage.getItem('currency') as string)
              )}
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button as={Link} to="/payment" colorScheme="blue">
              Go To Cart
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </React.Fragment>
  );
};

export default Basket;
