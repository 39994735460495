interface SizeArray {
  [key: string]: {
    [key: string]: string;
  };
}

export const productGroupSizes: SizeArray = {
  BRACELET: {
    S: '14-16 cm',
    M: '16-18 cm',
    L: '18-20 cm'
  },
  OCB: {
    S: '14-16 cm',
    M: '16-18 cm',
    L: '18-20 cm'
  },
  ANKLETS: {
    S: '23 cm',
    M: '25 cm',
    L: '27 cm'
  },
  NECKLACE: {
    S: '45 cm',
    SP: '55 cm',
    M: '65 cm',
    MP: '72 cm',
    L: '80 cm'
  },
  OCN: {
    S: '45 cm',
    SP: '55 cm',
    M: '65 cm',
    MP: '72 cm',
    L: '80 cm'
  }
};
