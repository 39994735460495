import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  Input,
  Button,
  Box,
  Flex,
  Container,
  Spinner,
  SimpleGrid,
  Select
} from '@chakra-ui/react';
import { useWebProductGroupsQuery } from '../../generated/graphql';
import 'react-image-lightbox/style.css';
import { IoMdReturnLeft } from 'react-icons/io';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Pagination from '../ui/Pagination';
import ProductItem from '../product/ProductItem';
import { HiArrowUp, HiArrowDown } from 'react-icons/hi';
export const Category = () => {
  const urlParams = useParams();
  const recordsPerPage = 51;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('order_ASC');
  const navigate = useNavigate();

  function handleChange(value: string) {
    if (value && value !== '') {
      return navigate(`/category/${value.toLowerCase()}`);
    }
  }

  const { data, loading, error } = useWebProductGroupsQuery({
    variables: {
      category: urlParams.sku?.toUpperCase() || 'GOLD',
      first: recordsPerPage,
      skip: (Number(page) - 1) * recordsPerPage,
      orderBy: sort
    }
  });

  if (error) {
    return <Box>{error.message} abc</Box>;
  }

  return (
    <Layout>
      <Container maxW="container.xl">
        {loading ? (
          <Box>
            <Spinner />
          </Box>
        ) : (
          <Box>
            <Box p={4} bg="gray.200" my={6}>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box fontWeight={'semibold'}>
                  {data?.webPartnerProductGroups.category.sku} -
                  {
                    data?.webPartnerProductGroups.category.description.find(
                      (l) => l.language === 'en'
                    )?.title
                  }
                </Box>
                <Flex justifyContent="flex-end">
                  <Flex mx={3} alignItems={'center'} justifyContent="flex-end">
                    <Box mr={4}>Sort</Box>
                    <Box bg="white" rounded="md">
                      <Select
                        onChange={(e) => {
                          setSort(e.target.value);
                        }}
                        value={sort}
                      >
                        <option value="order_ASC">Default Order</option>
                        <option value="basePrice_ASC">
                          Price: Low to High
                        </option>
                        <option value="basePrice_DESC">
                          Price: High to Low
                        </option>
                      </Select>
                    </Box>
                  </Flex>
                  {data?.webPartnerProductGroups?.subCategories &&
                    data?.webPartnerProductGroups?.subCategories.length > 0 && (
                      <Box>
                        <Flex alignItems={'center'} justifyContent="flex-end">
                          <Box mr={4}>Filter</Box>
                          <Box bg="white" rounded="md">
                            <Select
                              onChange={(e) => handleChange(e.target.value)}
                              value={urlParams.sku?.toUpperCase()}
                            >
                              <option
                                value={
                                  data.webPartnerProductGroups.category
                                    .mainCategory
                                    ? data.webPartnerProductGroups.category
                                        .mainCategory.sku
                                    : data.webPartnerProductGroups.category.sku
                                }
                              >
                                All{' '}
                                {data?.webPartnerProductGroups.category
                                  ?.mainCategory
                                  ? data?.webPartnerProductGroups?.category?.mainCategory.description.find(
                                      (l) => l.language === 'en'
                                    )?.title
                                  : data?.webPartnerProductGroups.category?.description.find(
                                      (l) => l.language === 'en'
                                    )?.title}
                              </option>
                              {data?.webPartnerProductGroups.subCategories.map(
                                (s) => {
                                  return (
                                    <option key={s.id} value={s.sku}>
                                      {
                                        s.description.find(
                                          (l) => l.language === 'en'
                                        )?.title
                                      }
                                    </option>
                                  );
                                }
                              )}
                            </Select>
                          </Box>
                        </Flex>
                      </Box>
                    )}
                </Flex>
              </SimpleGrid>
            </Box>

            <SimpleGrid
              columns={{
                base: 1,
                md: 3
              }}
              gap={6}
            >
              {data &&
                data.webPartnerProductGroups.productGroups.map((c) => {
                  const descriptions = c.description.find(
                    (l) => l.language === 'en'
                  );

                  return (
                    <ProductItem
                      key={c.id}
                      categorySku={c.category.sku}
                      sku={c.sku}
                      title={descriptions?.title || c.sku}
                      photos={c.photos}
                    />
                  );
                })}
            </SimpleGrid>
            <Pagination
              page={Number(page)}
              setPage={setPage}
              totalPages={Math.ceil(
                (data?.webPartnerProductGroups?.productsCount ||
                  recordsPerPage) / recordsPerPage
              )}
            />
          </Box>
        )}
      </Container>
    </Layout>
  );
};
