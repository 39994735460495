/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useWebWholesaleOrdersQuery } from '../../generated/graphql';
import Heading from '../ui/Heading';
import { Layout } from '../Layout';
import {
  Container,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Box
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import formatMoney from '../../utils/formatMoney';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const transitionValues = {
  duration: 1.5,
  repeat: Infinity,
  ease: 'easeInOut'
};
export const Orders = () => {
  const { data, loading, error } = useWebWholesaleOrdersQuery({
    variables: {
      id: localStorage.getItem('store') as string
    }
  });

  return (
    <Layout>
      <Container maxW="container.xl">
        <Heading title="Orders" />
        <Table variant={'striped'}>
          <Thead>
            <Tr>
              <Th width={'20px'}>#</Th>
              <Th
                display={{
                  base: 'none',
                  md: 'table-cell'
                }}
              >
                Order ID
              </Th>
              <Th>Order Date</Th>

              <Th>Total Price</Th>
              <Th
                display={{
                  base: 'none',
                  md: 'table-cell'
                }}
                textAlign={'center'}
              >
                Status
              </Th>
              <Th textAlign={'center'} width={'200px'}>
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={6}>
                  <Spinner />
                </Td>
              </Tr>
            ) : (
              data?.webWholesaleOrders.map((order, index) => {
                console.log('🌵💜🐢', order);
                let totalPrice = 0;
                const currency = order.products[0].price.currency.code;
                order.products.map((product) => {
                  totalPrice += product.price.price * product.quantity;
                });
                return (
                  <Tr key={order.id}>
                    <Td>{index + 1}</Td>
                    <Td color={'blue.500'}>#{order.conversationId}</Td>
                    <Td>
                      {dayjs(order.createdAt).format('DD-MMMM-YYYY HH:mm')}
                    </Td>
                    <Td>{formatMoney(totalPrice, currency)}</Td>
                    <Td fontWeight={'bold'}>
                      <Box
                        display={'flex'}
                        justifyContent="center"
                        alignItems="center"
                        py={4}
                      >
                        {order.orderStatus === 'SUCCESS'
                          ? 'Order Completed'
                          : 'Inprogress'}
                        <motion.span
                          style={{
                            display: 'block',
                            width: '15px',
                            height: '15px',
                            backgroundColor:
                              order.orderStatus === 'SUCCESS'
                                ? 'green'
                                : 'orange',
                            borderRadius: '15px',
                            marginLeft: '5px'
                          }}
                          animate={{ scale: [1, 1.1, 1] }}
                          initial={{ scale: 1 }}
                          transition={transitionValues}
                        />
                      </Box>
                    </Td>
                    <Td textAlign={'center'}>
                      <Link to={`/order/${order.id}`}>
                        <Text
                          w={'50%'}
                          mx="auto"
                          py={2}
                          color="white"
                          bg={'#862337'}
                          rounded="full"
                        >
                          View
                        </Text>
                      </Link>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </Container>
    </Layout>
  );
};
