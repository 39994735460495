import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import { createSlug } from './../../utils/createSlug';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import formatMoney from './../../utils/formatMoney';
import DropDown from '../material/DropDown';
import { productGroupSizes } from './../../utils/productGroupSizes';
import Swal from 'sweetalert2';

import {
  Input,
  Button,
  Box,
  Flex,
  Container,
  Spinner,
  SimpleGrid,
  GridItem,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
  Text,
  FormLabel,
  Heading,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  useToast
} from '@chakra-ui/react';
import {
  GetWholesalePriceQuery,
  useGetWholesalePriceQuery,
  useWebProductGroupQuery,
  useWebAddWholesaleBasketMutation,
  WebWholesaleBasketCountDocument,
  WebWholesaleBasketDocument
} from '../../generated/graphql';
import { IoMdReturnLeft } from 'react-icons/io';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FaAngleRight, FaHome, FaShoppingBag } from 'react-icons/fa';
import ProductItem from '../product/ProductItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, A11y } from 'swiper';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import RingSize from '../product/RingSize';

interface DropdownValue {
  name: string;
  label: string;
  value: string;
  color: string;
  sku: string;
}

export const Product = () => {
  const urlParams = useParams();
  const locale = 'en';
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState('');
  const [addBasket] = useWebAddWholesaleBasketMutation();
  const [productCode, setProductCode] = useState([]);
  const [productCodeValue, setProductCodeValue] = useState<DropdownValue[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  const [size, setSize] = useState<undefined | null | string>();
  const [showSize, setShowSize] = useState(true);
  const [ringSize, setRingSize] = useState<string>();
  const [basketNote, setBasketNote] = useState('');
  const [lightBoxPhotos, setLightBoxPhotos] = useState<string[]>([]);
  const [quantity, setQuantity] = useState(1);
  const [allPhotos, setAllPhotos] = useState<
    {
      url: string;
      atributes: string;
    }[]
  >([]);

  const { loading, data, error } = useWebProductGroupQuery({
    variables: {
      sku: urlParams.sku || ''
    }
  });

  const {
    data: priceData,
    loading: priceLoading,
    error: priceError
  } = useGetWholesalePriceQuery({
    variables: {
      sku: urlParams.sku || 'NOTFOUND',
      currency: localStorage.getItem('currency') as string,
      store: localStorage.getItem('store') as string
    }
  });

  useEffect(() => {
    if (data?.webProductGroup.sizes.length === 1) {
      setSize(data.webProductGroup.sizes[0].sku);
      setShowSize(false);
    }
  }, [data]);

  const navigate = useNavigate();

  if (error) {
    return <Box>{error.message}</Box>;
  }

  if (loading) {
    return (
      <Box>
        <Spinner />
      </Box>
    );
  }

  let description = data?.webProductGroup.description.find(
    (d) => d.language === 'en'
  );
  if (!description) {
    description = {
      id: 'customDescription',
      title: `${data?.webProductGroup.category.sku}-${data?.webProductGroup.sku}`,
      description: '',
      language: 'en'
    };
  }

  const mainCategoryDescription =
    data?.webProductGroup.category?.mainCategory?.description.find(
      (d) => d.language === locale
    );
  const categoryDescription = data?.webProductGroup.category?.description.find(
    (d) => d.language === locale
  );

  const renderMaterials = (materials: any[]) => {
    return (
      <Box>
        {materials.map((material: any, i: number) => {
          const materialTitle = material.description.find(
            (p: any) => p.language === locale
          ).title;
          let helper = null;
          if (
            material.sku === 'C' ||
            material.sku === 'SC' ||
            material.sku === 'YKC'
          ) {
            helper = 'ip-renkleri-1.png';
          }

          if (
            material.sku === 'E' ||
            material.sku === 'SEC' ||
            material.sku === 'TEC' ||
            material.sku === 'FEC'
          ) {
            helper = 'mine-renkleri-1.png';
          }
          return (
            <Box mb={4} key={i}>
              <DropDown
                helper={helper}
                productCodeValue={productCodeValue}
                setProductCodeValue={setProductCodeValue}
                order={i}
                productCode={productCode}
                setProductCode={setProductCode}
                placeholder={materialTitle}
                colors={material.availableColors}
                sku={material.sku}
                language={locale}
                materialOrder={i}
              />
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderSizes = (
    sizes: GetWholesalePriceQuery['getWholesalePrice']['sizePrices']
  ) => {
    return (
      <SimpleGrid
        columns={{
          base: 2,
          sm: 2,
          md: 3
        }}
        gap={4}
      >
        {sizes
          .slice()
          .sort((a, b) => a.size.order - b.size.order)
          .map((productSize, i) => {
            const description = productSize.size.description.find(
              (d) => d.language === locale
            );
            let sizeDescription = '';
            // productGroup.category.mainCategory
            const mainCategory = data!.webProductGroup.category.mainCategory;
            if (mainCategory && productGroupSizes[mainCategory.sku]) {
              if (productGroupSizes[mainCategory.sku][productSize.size.sku]) {
                sizeDescription =
                  productGroupSizes[mainCategory.sku][productSize.size.sku];
              }
            } else {
              const subCategory = data?.webProductGroup.category;
              if (subCategory && productGroupSizes[subCategory.sku]) {
                if (productGroupSizes[subCategory.sku][productSize.size.sku]) {
                  sizeDescription =
                    productGroupSizes[subCategory.sku][productSize.size.sku];
                }
              }
            }

            return (
              <Box key={i} className="w-full">
                <Button
                  onClick={() => {
                    setSize(productSize.size.sku);
                    setAdditionalPrice(
                      currency === 'USD'
                        ? Math.ceil(Number(productSize.prices[0]?.price) * 1.1)
                        : productSize.prices[0]?.price
                    );
                  }}
                  px={2}
                  py={1}
                  height="auto"
                  width="full"
                  fontSize={'sm'}
                  color="gray.500"
                  border={'1px solid'}
                  borderColor="#ccc"
                  flexDirection={'column'}
                  fontWeight={size === productSize.size.sku ? 'bold' : 'normal'}
                  backgroundColor={
                    size === productSize.size.sku ? 'green.100' : 'transparent'
                  }
                  variant={'unstyled'}
                  _focus={{ outline: 'none' }}
                  _hover={{
                    backgroundColor:
                      size === productSize.size.sku ? 'green.100' : 'juju.200'
                  }}
                >
                  <Box>{description?.title}</Box>
                  {sizeDescription && <Box>&nbsp;({sizeDescription})</Box>}
                </Button>
              </Box>
            );
          })}
      </SimpleGrid>
    );
  };

  const handleBasket = async () => {
    const removeNulledArray = productCode.filter((p) => p);

    if (removeNulledArray.length !== data?.webProductGroup.materials.length) {
      return toast({
        status: 'error',
        description: 'All options are required'
      });
    }
    if (!size) {
      return toast({
        status: 'error',
        description: 'Size selection is required'
      });
    }

    if (data.webProductGroup.category.mainCategory) {
      if (
        data.webProductGroup.category.mainCategory.sku === 'RINGS' ||
        data.webProductGroup.category.sku === 'OCR'
      ) {
        if (!ringSize) {
          return toast({
            status: 'error',
            description: 'Ring size is required'
          });
        }
      }
    }

    const setMaterials = [];
    productCode.map((p) => {
      Object.keys(p).map(function (key) {
        setMaterials.push(`${key}:${p[key]}`);
      });
    });

    const mats = productCode.map((code) => {
      return Object.entries(code).map((k) => {
        return `${k[0]}:${k[1]}`;
      });
    });

    const wMath = mats.join('-');
    const formattedSku = `${data.webProductGroup.category.sku}-${
      data.webProductGroup.sku
    }-${size}${wMath.length > 0 ? `-${wMath}` : ''}`;

    const variables = {
      storeId: localStorage.getItem('store') as string,
      additionalInfo: basketNote,
      ringSize,
      sku: formattedSku,
      quantity: quantity
    };

    try {
      await addBasket({
        variables,
        refetchQueries: [
          {
            query: WebWholesaleBasketCountDocument,
            variables: {
              storeId: localStorage.getItem('store') as string
            }
          },
          {
            query: WebWholesaleBasketDocument,
            variables: {
              storeId: localStorage.getItem('store') as string,
              currency: localStorage.getItem('currency') as string
            }
          }
        ]
      });

      setProductCodeValue(() => []);
      setProductCode(() => []);
      setRingSize('');
      if (showSize) {
        setSize('');
      }
      setQuantity(1);
      setBasketNote('');

      Swal.fire({
        text: 'The item has been successfully added to your cart',
        icon: 'success',
        showDenyButton: false,
        confirmButtonText: 'Close',
        confirmButtonColor: '#862337'
      });
    } catch (error) {
      console.log('Out of order');
    }
  };
  const currency = localStorage.getItem('currency') as string;
  return (
    <Layout>
      <Box
        position={'fixed'}
        display={{
          base: 'block',
          md: 'none'
        }}
        bottom={0}
        left={0}
        right={0}
        py={2}
        zIndex={10}
        bgColor="white"
        borderTop="1px solid"
        borderColor={'gray.300'}
      >
        <Box px={6}>
          <Flex justifyContent={'space-between'} alignItems="center">
            <Box>
              <Box fontSize={'lg'} fontWeight="bold">
                {priceLoading ? (
                  <Spinner size={'xs'} />
                ) : priceError ? (
                  <Box>Ask for price</Box>
                ) : priceData ? (
                  <Box>
                    {formatMoney(
                      Math.ceil(
                        (priceData.getWholesalePrice.price + additionalPrice) *
                          (currency === 'TL'
                            ? 1
                            : priceData.getWholesalePrice.currency === 'TRY'
                            ? 0.5
                            : currency === 'USD'
                            ? 1.1
                            : 1)
                      ),
                      currency === 'USD'
                        ? 'USD'
                        : currency === 'TL'
                        ? 'TRY'
                        : priceData.getWholesalePrice.currency
                    )}
                    <Text fontSize={'xs'} fontWeight={'medium'} color={'#999'}>
                      TOTAL:
                      {formatMoney(
                        Math.ceil(
                          (priceData.getWholesalePrice.price +
                            additionalPrice) *
                            (currency === 'TL'
                              ? 1
                              : priceData.getWholesalePrice.currency === 'TRY'
                              ? 0.5
                              : currency === 'USD'
                              ? 1.1
                              : 1)
                        ) * quantity,
                        currency === 'USD'
                          ? 'USD'
                          : currency === 'TL'
                          ? 'TRY'
                          : priceData.getWholesalePrice.currency
                      )}
                    </Text>
                  </Box>
                ) : (
                  <Spinner size={'xs'} />
                )}
              </Box>
            </Box>
            <Flex alignItems={'center'}>
              {!priceError && (
                <Button
                  backgroundColor={'green.100'}
                  border="1px solid"
                  borderColor={'green.200'}
                  color="juju.400"
                  type="button"
                  textTransform={'uppercase'}
                  display="flex"
                  alignItems={'center'}
                  rounded="md"
                  _hover={{
                    backgroundColor: 'green.400',
                    color: 'white'
                  }}
                  _focus={{
                    backgroundColor: 'green.400',
                    color: 'white'
                  }}
                  onClick={handleBasket}
                  size="md"
                >
                  <Icon as={FaShoppingBag} mr={2} />
                  Add to Basket
                </Button>
              )}
            </Flex>
          </Flex>
        </Box>
      </Box>

      <Box
        bg={'gray.100'}
        mb={6}
        pb={{
          base: 4,
          md: 0
        }}
      >
        <Container maxW="container.xl">
          <Box maxWidth={'1600px'} mx="auto" px={6}>
            <Flex
              flexDir={{
                base: 'column',
                md: 'row'
              }}
              alignItems={'center'}
              justifyContent={{
                base: 'center',
                md: 'space-between'
              }}
            >
              <Box my={4}>
                <Text
                  fontSize="sm"
                  color={'gray.600'}
                  fontWeight="medium"
                  textAlign={{
                    base: 'center',
                    md: 'left'
                  }}
                >
                  {data?.webProductGroup.category.sku}-
                  {data?.webProductGroup.sku}
                </Text>
                <Heading
                  as={'h1'}
                  fontSize="3xl"
                  color={'juju.500'}
                  fontWeight="thin"
                >
                  {description.title}
                </Heading>
              </Box>
              <Box>
                <Breadcrumb
                  spacing="8px"
                  alignItems={'center'}
                  textTransform="uppercase"
                  separator={<Icon mt={2} color="juju.200" as={FaAngleRight} />}
                  fontSize="sm"
                >
                  <BreadcrumbItem>
                    <BreadcrumbLink href="/">
                      <Icon as={FaHome} mt={1} />
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  {data?.webProductGroup.category?.mainCategory && (
                    <BreadcrumbItem>
                      <BreadcrumbLink
                        href={`/category/${data.webProductGroup.category?.mainCategory?.sku.toLowerCase()}`}
                      >
                        {mainCategoryDescription?.title}
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                  )}

                  {data?.webProductGroup.category && (
                    <BreadcrumbItem>
                      <BreadcrumbLink
                        href={`/category/${data.webProductGroup.category?.sku.toLowerCase()}`}
                      >
                        {categoryDescription?.title}
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                  )}
                </Breadcrumb>
              </Box>
            </Flex>
          </Box>
        </Container>
      </Box>
      <Container maxW="container.xl">
        <Box maxWidth={'1600px'} mb={6} mx="auto" px={6}>
          <SimpleGrid
            columns={{
              base: 1,
              md: 3
            }}
            gap={6}
          >
            <GridItem
              colSpan={{ base: 1, md: 2 }}
              order={1}
              display={{ base: 'none', md: 'grid' }}
              className="burada"
            >
              <Box>
                <Flex wrap={'wrap'} gap={2}>
                  {data?.webProductGroup.photos.map((p, i) => {
                    return (
                      <Box w={'45%'} key={i}>
                        <button
                          onClick={() => {
                            setPhotoIndex(
                              `https://images.jujumood.com/${p}?width=1200&height=1200`
                            );
                            setIsOpen(true);
                          }}
                        >
                          <Box border="1px solid" borderColor={'gray.500'}>
                            <img
                              src={`https://images.jujumood.com/${p}?width=500&height=500&trim=20&bg=ffffff&fit=contain&extend=10`}
                              width={'501'}
                              height="501"
                              alt={`Juju ${data?.webProductGroup.sku}-${i}`}
                            />
                          </Box>
                        </button>
                      </Box>
                    );
                  })}
                   {' '}
                  {data?.webProductGroup.lifeStylePhotos.map((p, i) => {
                    return (
                      <Box w={'45%'} key={i}>
                        <button
                          onClick={() => {
                            setPhotoIndex(
                              `https://images.jujumood.com/${p}?width=1200&height=1200`
                            );
                            setIsOpen(true);
                          }}
                        >
                          <Box border="1px solid" borderColor={'gray.500'}>
                            <img
                              src={`https://images.jujumood.com/${p}?format=webp&quality=90&width=500&height=500&fit=cover`}
                              width={'501'}
                              height="501"
                              alt={`Juju ${data?.webProductGroup.sku}-${i}`}
                            />
                          </Box>
                        </button>
                      </Box>
                    );
                  })}
                </Flex>
              </Box>
            </GridItem>
            <GridItem order={2}>
              <Box px={6} background="white">
                <Box>
                  <Box
                    display={{
                      base: 'block',
                      md: 'none'
                    }}
                    mb={6}
                  >
                    <Swiper
                      modules={[Scrollbar, A11y]}
                      slidesPerView={1}
                      scrollbar={{ draggable: true }}
                    >
                      {data?.webProductGroup.photos.map((p, i) => {
                        return (
                          <SwiperSlide key={`mobile${i}`}>
                            <button
                              onClick={() => {
                                setPhotoIndex(
                                  `https://images.jujumood.com/${p}?width=1200&height=1200`
                                );
                                setIsOpen(true);
                              }}
                            >
                              <LazyLoadImage
                                src={`https://images.jujumood.com/${p}?width=500&height=500&trim=20&bg=ffffff&fit=contain&extend=10`}
                                width={'501'}
                                height="501"
                                alt={`Juju ${data?.webProductGroup.sku} ${i}`}
                              />
                            </button>
                          </SwiperSlide>
                        );
                      })}
                      {data?.webProductGroup.lifeStylePhotos.map((p, i) => {
                        return (
                          <SwiperSlide key={`mobile${i}`}>
                            <button
                              onClick={() => {
                                setPhotoIndex(
                                  `https://images.jujumood.com/${p}?width=1200&height=1200`
                                );
                                setIsOpen(true);
                              }}
                            >
                              <LazyLoadImage
                                src={`https://images.jujumood.com/${p}?width=500&height=500&trim=20&bg=ffffff&fit=contain&extend=10`}
                                width={'501'}
                                height="501"
                                alt={`Juju ${data?.webProductGroup.sku} ${i}`}
                              />
                            </button>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </Box>

                  {renderMaterials(data!.webProductGroup.materials)}
                  {showSize ? (
                    <Box mb={4}>
                      <FormLabel
                        fontSize={'xs'}
                        mb={1}
                        color="gray.500"
                        fontWeight="semibold"
                        letterSpacing={0.5}
                        textTransform="uppercase"
                      >
                        Size
                      </FormLabel>

                      {priceLoading ? (
                        <Spinner />
                      ) : priceError ? (
                        <Box>Ask for price</Box>
                      ) : (
                        priceData &&
                        renderSizes(priceData.getWholesalePrice.sizePrices)
                      )}
                    </Box>
                  ) : (
                    <Box mb={4}>
                      <Flex alignItems={'center'}>
                        <FormLabel
                          fontSize={'xs'}
                          mb={1}
                          color="gray.500"
                          fontWeight="semibold"
                          letterSpacing={0.5}
                          textTransform="uppercase"
                        >
                          Size
                        </FormLabel>
                        <RingSize
                          setRingSize={setRingSize}
                          ringSize={ringSize}
                        />
                      </Flex>
                      <Input
                        value={ringSize}
                        onChange={(e) => setRingSize(e.target.value)}
                      />
                    </Box>
                  )}

                  <Box mb={[24, 4]}>
                    <FormLabel
                      fontSize={'xs'}
                      mb={1}
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing={0.5}
                      textTransform="uppercase"
                    >
                      Quantity
                    </FormLabel>
                    <NumberInput
                      onChange={(e) => setQuantity(Number(e))}
                      defaultValue={1}
                      value={quantity}
                      min={1}
                      max={20}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Box>
                  <Flex
                    display={{
                      base: 'none',
                      md: 'flex'
                    }}
                    justifyContent={'space-between'}
                    alignItems="center"
                    pt={4}
                    borderTop="1px solid"
                    borderTopColor={'gray.200'}
                  >
                    <Box fontSize={'2xl'} fontWeight="bold">
                      <Box>
                        <Box fontSize={'xl'} fontWeight="bold">
                          {priceLoading ? (
                            <Spinner size={'xs'} />
                          ) : priceData ? (
                            <Box>
                              {formatMoney(
                                Math.ceil(
                                  (priceData.getWholesalePrice.price +
                                    additionalPrice) *
                                    (currency === 'TL'
                                      ? 1
                                      : priceData.getWholesalePrice.currency ===
                                        'TRY'
                                      ? 0.5
                                      : currency === 'USD'
                                      ? 1.1
                                      : 1)
                                ),
                                currency === 'USD'
                                  ? 'USD'
                                  : currency === 'TL'
                                  ? 'TRY'
                                  : priceData.getWholesalePrice.currency
                              )}
                              <Text
                                fontSize={'sm'}
                                fontWeight={'medium'}
                                color={'#999'}
                              >
                                TOTAL:
                                {formatMoney(
                                  Math.ceil(
                                    (priceData.getWholesalePrice.price +
                                      additionalPrice) *
                                      (currency === 'TL'
                                        ? 1
                                        : priceData.getWholesalePrice
                                            .currency === 'TRY'
                                        ? 0.5
                                        : currency === 'USD'
                                        ? 1.1
                                        : 1)
                                  ) * quantity,
                                  currency === 'USD'
                                    ? 'USD'
                                    : currency === 'TL'
                                    ? 'TRY'
                                    : priceData.getWholesalePrice.currency
                                )}
                              </Text>
                            </Box>
                          ) : (
                            <Spinner size={'xs'} />
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <Flex alignItems={'center'}>
                      {!priceError && (
                        <Button
                          backgroundColor={'green.100'}
                          border="1px solid"
                          borderColor={'green.200'}
                          color="juju.400"
                          type="button"
                          textTransform={'uppercase'}
                          display="flex"
                          alignItems={'center'}
                          rounded="md"
                          _hover={{
                            backgroundColor: 'green.400',
                            color: 'white'
                          }}
                          _focus={{
                            backgroundColor: 'green.400',
                            color: 'white'
                          }}
                          size="lg"
                          onClick={handleBasket}
                        >
                          <Icon as={FaShoppingBag} mr={2} />
                          Add to Basket
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </Box>
              </Box>
            </GridItem>
          </SimpleGrid>
        </Box>
        {isOpen && (
          <Lightbox
            mainSrc={photoIndex}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}
      </Container>
    </Layout>
  );
};
