import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  Container,
  IconButton,
  Icon,
  Spinner
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HiAnnotation, HiLogout, HiSearch } from 'react-icons/hi';
import { GiHamburgerMenu } from 'react-icons/gi';

import { UserType } from '../generated/graphql';
import Basket from './basket/Basket';

export const Layout: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  function logOut() {
    localStorage.removeItem('token');
    window.location.reload();
  }

  return (
    <Box>
      <Box bg="#862337">
        <Container maxW="container.xl">
          <Box
            display={{
              base: 'block',
              md: 'none'
            }}
            as="section"
            py={5}
            px={6}
            shadow={'md'}
          >
            <Box as="nav">
              <Box position="relative" display="flex" alignItems={'center'}>
                <Box display="flex" alignItems={'center'} mr="auto">
                  <Box bgColor={'white'} p={1}>
                    <Link to="/dashboard">
                      <img
                        src="https://images.jujumood.com/logo.png?format=webp&height=30"
                        alt="Juju Logo"
                      />
                    </Link>
                  </Box>
                </Box>
                <Box
                  display={{
                    base: 'flex',
                    md: 'none'
                  }}
                  justifyContent="flex-end"
                >
                  <Button
                    className="flex items-center"
                    variant={'outline'}
                    onClick={() => setIsOpen(true)}
                  >
                    <Icon
                      as={GiHamburgerMenu}
                      color="white"
                      fontSize={'20px'}
                    />
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              display={{
                base: isOpen ? 'block' : 'none',
                md: 'none'
              }}
              position="fixed"
              top={0}
              left={0}
              bottom={0}
              width="80%"
              zIndex={50}
            >
              <Box
                position={'fixed'}
                width="20%"
                right={0}
                top={0}
                bottom={0}
                bgColor="blackAlpha.500"
                zIndex={49}
                onClick={() => setIsOpen(false)}
              ></Box>
              <Box
                as="nav"
                display="flex"
                flexDirection={'column'}
                py={6}
                px={6}
                width="full"
                height={'full'}
                bgColor="white"
                borderRight={'1px solid '}
                borderColor="gray.300"
              >
                <div className="flex items-center mb-8">
                  <a
                    className="mr-auto text-3xl font-bold leading-none"
                    href="/"
                  >
                    <img
                      src="https://images.jujumood.com/logo.png?format=webp&height=50"
                      alt="Juju Logo"
                    />
                  </a>
                  <button className="navbar-close">
                    <svg
                      className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div>
                  <Flex flexDirection={'column'}>
                    <Link to="/dashboard">
                      <Box fontWeight={'semibold'} fontSize="xl" mb={4}>
                        Categories
                      </Box>
                    </Link>
                    <Link to="/orders">
                      <Box fontWeight={'semibold'} fontSize="xl" mb={4}>
                        Orders
                      </Box>
                    </Link>
                    <Link to="/payment">
                      <Box fontWeight={'semibold'} fontSize="xl" mb={4}>
                        Cart
                      </Box>
                    </Link>
                    <Box
                      onClick={logOut}
                      cursor="pointer"
                      fontWeight={'semibold'}
                      fontSize="xl"
                      mb={4}
                    >
                      Logout
                    </Box>
                  </Flex>
                </div>
              </Box>
            </Box>
          </Box>

          <Flex
            display={{
              base: 'none',
              md: 'flex'
            }}
            color="white"
            justifyContent="space-between"
            alignItems="center"
            p={4}
          >
            <Box bgColor={'white'} p={2}>
              <Link to="/dashboard">
                <img
                  src="https://images.jujumood.com/logo.png?format=webp&width=35&height=50"
                  alt="Juju Logo"
                />
              </Link>
            </Box>
            <Flex
              justifyContent={'center'}
              alignItems="center"
              experimental_spaceX={4}
            >
              <Link to="/dashboard">
                <Box fontWeight={'semibold'}>Categories</Box>
              </Link>
              <Link to="/orders">
                <Box fontWeight={'semibold'}>Orders</Box>
              </Link>
            </Flex>
            <Flex
              color="black"
              alignItems="space-between"
              p={2}
              experimental_spaceX={4}
            >
              <Link to={'/search'}>
                <Icon as={HiSearch} color="white" fontSize={'xl'} />
              </Link>
              <Basket />
              <Button
                aria-label="LogOut"
                variant={'link'}
                onClick={logOut}
                mx={2}
                color="white"
                fontSize="17px"
              >
                Logout
              </Button>
            </Flex>
          </Flex>
        </Container>
      </Box>
      <hr />
      <Box>{children}</Box>
    </Box>
  );
};
