import { useState, useEffect } from 'react';
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Stack,
  Heading,
  useToast
} from '@chakra-ui/react';
import { useLoginPartnerMutation } from '../../generated/graphql';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import { useNavigate } from 'react-router-dom';
import './../../styles/home.css';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginUser] = useLoginPartnerMutation();
  const toast = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard');
    }
  }, [navigate]);
  async function onSubmit() {
    try {
      const userData = await loginUser({
        variables: {
          data: {
            email,
            password
          }
        }
      });
      const token = userData.data?.loginPartner.token;
      const role = userData.data?.loginPartner.user.userType;
      const store = userData.data?.loginPartner.store;
      const currency = userData.data?.loginPartner.currency;
      const userId = userData.data?.loginPartner.user.id;
      localStorage.setItem('token', token || '');
      localStorage.setItem('role', role || '');
      localStorage.setItem('store', store || '');
      localStorage.setItem('currency', currency || '');
      localStorage.setItem('userId', userId || '');
      toast({
        title: 'Success',
        status: 'success',
        isClosable: true
      });
      window.location.reload();
    } catch (error) {
      console.log('🌵💜🐢 error', error);
      toast({
        title: 'Wrong username or password.',
        status: 'error'
      });
    }
  }

  return (
    <Box
      width={'100vw'}
      height={'100vh'}
      padding="0"
      margin="0"
      textAlign="center"
      fontSize="xl"
      background={`url(https://images.jujumood.com/b2b/b2b.png) no-repeat center center`}
      backgroundSize="cover"
    >
      <Flex
        minH={{
          base: '100vh',
          md: '100vh'
        }}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection="column"
      >
        <Box>
          <Stack
            spacing={{
              base: 2,
              md: 8
            }}
            mx={'auto'}
            maxW={'lg'}
            backgroundColor="whiteAlpha.900"
            width={'100%'}
            p={6}
            shadow="lg"
          >
            <Stack align={'center'}>
              <img
                src="https://images.jujumood.com/logo.png?format=webp&width=60"
                alt="Juju B2B"
              />

              <Text fontSize={'lg'} color={'gray.600'} fontWeight="bold">
                B2B
              </Text>
            </Stack>
            <Box
              w={{
                base: '100%',
                md: '25vw'
              }}
              rounded={'lg'}
              mx="auto"
              p={8}
            >
              <Stack spacing={4}>
                <FormControl id="username">
                  <FormLabel>E-Mail</FormLabel>
                  <Input
                    onChange={(e: any) => setEmail(e.target.value)}
                    type="text"
                    placeholder="E-Mail"
                  />
                </FormControl>
                <FormControl id="password">
                  <FormLabel>Password</FormLabel>
                  <Input
                    onChange={(e: any) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password"
                  />
                </FormControl>
                <Stack spacing={10}>
                  <Button
                    bg={'#862337'}
                    color={'white'}
                    _hover={{
                      bg: '#b4d2da',
                      color: 'gray.800'
                    }}
                    onClick={onSubmit}
                  >
                    Login
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};
