import { Box, Spinner } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGetWholesalePriceQuery } from './../../generated/graphql';
import formatMoney from '../../utils/formatMoney';

interface Props {
  photos?: string;
  title: string;
  sku: string;
}
const ProductItem: React.FC<Props> = ({ sku, title, photos }) => {
  const { data, loading, error } = useGetWholesalePriceQuery({
    variables: {
      currency: localStorage.getItem('currency') as string,
      store: localStorage.getItem('store') as string,
      sku
    }
  });

  const currency = localStorage.getItem('currency');

  let extend = 0;

  return (
    <Box
      _hover={{ borderColor: 'green.600' }}
      border="1px solid"
      borderColor="gray.300"
    >
      <Link to={`/product/${sku.toLowerCase()}`}>
        <Box p={6}>
          <Box mb={6}>
            <img
              src={`https://images.jujumood.com/${photos}?format=webp&width=363&height=363&quality=75&trim=25&fit=contain&bg=ffffff&extend=30,${extend},30,${extend}`}
              alt={`${sku}`}
            />
          </Box>
          <Box textAlign={'center'}>
            <Box fontSize="lg" fontWeight={'bold'}>
              {title}
            </Box>
            {/* <Box fontSize={'sm'} color="gray.400">
              {categorySku}-{sku}
            </Box> */}
            <Box fontSize={'lg'} color="gray.800" fontWeight={'bold'}>
              {loading ? (
                <Spinner />
              ) : error ? (
                <Box>ASK FOR PRICE</Box>
              ) : (
                <Box>
                  {formatMoney(
                    Math.ceil(
                      (data?.getWholesalePrice.price || 0) *
                        (currency === 'TRY'
                          ? 0.5
                          : currency === 'USD'
                          ? 1.1
                          : 1)
                    ),
                    currency === 'USD'
                      ? 'USD'
                      : data?.getWholesalePrice.currency || ''
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default ProductItem;
