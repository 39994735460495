import { Box, Flex, Icon, FormLabel } from '@chakra-ui/react';
import { IoMdHelpCircle } from 'react-icons/io';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import ReactSelect from 'react-select';

interface DropdownValue {
  name: string;
  label: string;
  value: string;
  color: string;
  sku: string;
}

interface IDropDown {
  helper?: string | null;
  productCode: any[];
  setProductCode: any;
  placeholder: string;
  order: number;
  productCodeValue: DropdownValue[];
  setProductCodeValue: React.Dispatch<React.SetStateAction<DropdownValue[]>>;
  colors: {
    id: string;
    sku: string;
    code: string;
    description: {
      id: string;
      title: string;
      description?: string;
      language: string;
    }[];
  }[];
  sku: string;
  language: string;
  materialOrder: number;
}

const dot = (color = 'gray') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 15,
    width: 15
  }
});

const DropDown: React.FC<IDropDown> = (props) => {
  const arrayOrder = props.materialOrder;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box>
      <Flex alignItems={'center'} mb={1}>
        <FormLabel
          fontSize={'xs'}
          color="gray.500"
          fontWeight="semibold"
          letterSpacing={0.5}
          textTransform="uppercase"
        >
          {props.placeholder}
        </FormLabel>

        {props.helper && (
          <Icon
            onClick={() => setIsOpen(true)}
            as={IoMdHelpCircle}
            mt={-2}
            cursor="pointer"
            color="juju.100"
          />
        )}
      </Flex>
      {/* props.productCodeValue.filter((p) => p.sku === props.sku).length === 0 */}
      <ReactSelect
        isClearable={false}
        {...(props.productCodeValue.filter((p) => p.sku === props.sku)
          .length === 0
          ? {
              ...{
                value: []
              }
            }
          : {
              ...{
                value: props.productCodeValue.filter((p) => p.sku === props.sku)
              }
            })}
        placeholder={props.placeholder}
        options={props.colors.map((item) => {
          const colorTitle =
            item?.description.find((p) => p.language === props.language)
              ?.title || 'Not Found';
          return {
            name: `${colorTitle}`,
            label: `${colorTitle}`,
            value: `${props.sku}:${item.sku}`,
            color: item.code,
            sku: props.sku
          };
        })}
        styles={{
          singleValue: (styles, { data }) => ({
            ...styles,
            ...dot(data.color)
          }),
          option: (styles, { data }) => ({
            ...styles,
            ...dot(data.color)
          }),
          valueContainer: (styles) => ({
            ...styles,
            paddingTop: '7px',
            paddingBottom: '7px'
          })
        }}
        onChange={(e, a) => {
          if (e) {
            const filtered = props.productCodeValue.filter(
              (p) => p.sku !== props.sku
            );

            props.setProductCodeValue([
              ...filtered,
              {
                name: e.name,
                label: e.label,
                color: e.color,
                value: e.value,
                sku: props.sku
              }
            ]);

            const material = e.value.split(':');
            const materialSku = material[0];
            const colorSku = material[1];
            const oldArray = props.productCode;
            oldArray[arrayOrder] = { [materialSku]: colorSku };
            return props.setProductCode(() => [...oldArray]);
          }

          if (a.action === 'clear') {
            const filtered = props.productCodeValue.filter(
              (p) => p.sku !== props.sku
            );
            props.setProductCodeValue(() => [...filtered]);
          }
        }}
      />
      {isOpen && (
        <Lightbox
          mainSrc={`${process.env.REACT_APP_CDN_URL}/${props.helper}?format=webp&quality=60`}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </Box>
  );
};

export default DropDown;
