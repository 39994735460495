import React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
  BrowserRouter as Router
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import client from '../lib/index';
import { chakraTheme } from '../theme';

import { Login } from '../components/page/Login';
import { Dashboard } from '../components/page/Dashboard';
import { Category } from '../components/page/Category';
import { Product } from '../components/page/Product';
import { Payment } from '../components/page/Payment';
import { Orders } from '../components/page/Orders';
import { Order } from '../components/page/Order';
import { Search } from '../components/page/Search';

function RequireAuth() {
  const token = localStorage.getItem('token');
  const location = useLocation();

  if (!token) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return <Outlet />;
}

const AppRouter = () => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route element={<RequireAuth />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/category/:sku" element={<Category />} />
              <Route path="/product/:sku" element={<Product />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/order/:id" element={<Order />} />
              <Route path="/search" element={<Search />} />
            </Route>
          </Routes>
        </Router>
      </ApolloProvider>
    </ChakraProvider>
  );
};

export default AppRouter;
