import React, { useRef, useState } from 'react';
import {
  Box,
  Drawer,
  useDisclosure,
  Button,
  Select,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Tr,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  TableContainer,
  Icon,
  Text,
  chakra,
  Flex
} from '@chakra-ui/react';
import { IoMdHelpCircle } from 'react-icons/io';

interface IProps {
  ringSize: string | undefined;
  setRingSize: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const data2: any = [
  {
    circumference: '37,8',
    diameter: '12,04',
    ringSize: '12',
    eu: '38',
    uk: 'A',
    usa: '0,5'
  },
  {
    circumference: '39,1',
    diameter: '12,45',
    ringSize: '12,5',
    eu: '39',
    uk: 'B',
    usa: '1'
  },
  {
    circumference: '40,4',
    diameter: '12,85',
    ringSize: '13',
    eu: '40',
    uk: 'C',
    usa: '1,5'
  },
  {
    circumference: '41,7',
    diameter: '13,26',
    ringSize: '13',
    eu: '42',
    uk: 'D',
    usa: '2'
  },
  {
    circumference: '42,9',
    diameter: '13,67',
    ringSize: '13,5',
    eu: '43',
    uk: 'E',
    usa: '2,5'
  },
  {
    circumference: '44,2',
    diameter: '14,07',
    ringSize: '14',
    eu: '44',
    uk: 'F',
    usa: '3'
  },
  {
    circumference: '45,5',
    diameter: '14,48',
    ringSize: '14,5',
    eu: '45',
    uk: 'G',
    usa: '3,5'
  },
  {
    circumference: '46,8',
    diameter: '14,88',
    ringSize: '15',
    eu: '47',
    uk: 'H',
    usa: '4'
  },
  {
    circumference: '48',
    diameter: '15,29',
    ringSize: '15',
    eu: '48',
    uk: 'I',
    usa: '4,5'
  },
  {
    circumference: '48,7',
    diameter: '15,49',
    ringSize: '15,5',
    eu: '49',
    uk: 'J',
    usa: '5'
  },
  {
    circumference: '50',
    diameter: '15,9',
    ringSize: '16',
    eu: '50',
    uk: 'K',
    usa: '5,5'
  },
  {
    circumference: '51,2',
    diameter: '16,31',
    ringSize: '16',
    eu: '51',
    uk: 'L',
    usa: '6'
  },
  {
    circumference: '52,5',
    diameter: '16,71',
    ringSize: '16,5',
    eu: '52',
    uk: 'M',
    usa: '6,5'
  },
  {
    circumference: '53,8',
    diameter: '17,12',
    ringSize: '17',
    eu: '54',
    uk: 'N',
    usa: '7'
  },
  {
    circumference: '55,1',
    diameter: '17,53',
    ringSize: '17,5',
    eu: '55',
    uk: 'O',
    usa: '7,5'
  },
  {
    circumference: '56,3',
    diameter: '17,93',
    ringSize: '18',
    eu: '56',
    uk: 'P',
    usa: '8'
  },
  {
    circumference: '57,6',
    diameter: '18,34',
    ringSize: '18',
    eu: '58',
    uk: 'Q',
    usa: '8,5'
  },
  {
    circumference: '58,9',
    diameter: '18,75',
    ringSize: '18,5',
    eu: '59',
    uk: 'R',
    usa: '9'
  },
  {
    circumference: '60,2',
    diameter: '19,15',
    ringSize: '19',
    eu: '60',
    uk: 'S',
    usa: '9,5'
  },
  {
    circumference: '61,4',
    diameter: '19,56',
    ringSize: '19,5',
    eu: '61',
    uk: 'T',
    usa: '10'
  },
  {
    circumference: '62,7',
    diameter: '19,96',
    ringSize: '20',
    eu: '63',
    uk: 'U',
    usa: '10,5'
  },
  {
    circumference: '64',
    diameter: '20,37',
    ringSize: '20',
    eu: '64',
    uk: 'V',
    usa: '11'
  },
  {
    circumference: '65,3',
    diameter: '20,78',
    ringSize: '20,5',
    eu: '65',
    uk: 'W',
    usa: '11,5'
  },
  {
    circumference: '66,6',
    diameter: '21,18',
    ringSize: '21',
    eu: '67',
    uk: 'X',
    usa: '12'
  },
  {
    circumference: '67,8',
    diameter: '21,59',
    ringSize: '21,5',
    eu: '68',
    uk: 'Y',
    usa: '12,5'
  },
  {
    circumference: '68,5',
    diameter: '21,79',
    ringSize: '22',
    eu: '69',
    uk: 'Z',
    usa: '13'
  }
];

const RingSize: React.VFC<IProps> = ({ ringSize, setRingSize }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [region, setRegion] = useState<string>('ringSize');

  const refs: any = useRef();

  const handleRingSizeSelect = (value: string) => {
    setRingSize(`${value} mm`);
    onClose();
  };
  return (
    <Box display="inline">
      <Icon
        onClick={onOpen}
        as={IoMdHelpCircle}
        mt={-2}
        cursor="pointer"
        color="juju.100"
      />

      <Drawer
        size="md"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={refs}
      >
        <DrawerOverlay />

        <DrawerContent>
          <DrawerBody>
            <Flex>
              <Text mt={6} mb={4} fontWeight="bold" fontSize={'lg'}>
                Ring Size Chart
              </Text>
              <DrawerCloseButton />
            </Flex>
            <Text mb={2}>
              Take a string or strip of paper 2mm wide and 100mm long. Wrap it
              around the base of your finger making sure it fits snug, but isn’t
              too tight. Mark the string/paper where it overlaps to form a
              circle. Measure the length and compare it to the chart below
              (using this measurement as your circumference).
            </Text>
            <Text mb={4}>
              When taking your finger measurement, make sure you do it a few
              times over a period of a day or so in order to gauge an accurate
              size. Your fingers react to warm and cold environments. If you are
              between sizes, order the larger size.
            </Text>

            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <Text
                      textAlign={'center'}
                      fontSize={{
                        base: 'xs',
                        md: 'md'
                      }}
                      wordBreak={'break-word'}
                    >
                      Finger Circumference (mm)
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      textAlign={'center'}
                      fontSize={{
                        base: 'xs',
                        md: 'md'
                      }}
                      wordBreak={'break-word'}
                    >
                      Diameter of Ring (mm)
                    </Text>
                  </Th>
                  <Th
                    fontSize={{
                      base: 'xs',
                      md: 'md'
                    }}
                    whiteSpace={'nowrap'}
                  >
                    <chakra.select
                      value={region}
                      onChange={(e) => setRegion(e.target.value)}
                    >
                      <option value="ringSize">Ring Size</option>
                      <option value="eu">Europe</option>
                      <option value="uk">UK</option>
                      <option value="usa">USA</option>
                    </chakra.select>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data2.map((item: any, i: number) => {
                  return (
                    <Tr key={i + 1}>
                      <Td>
                        <Button
                          size="sm"
                          background="transparent"
                          _focus={{}}
                          width="100%"
                          variant="outline"
                          onClick={() =>
                            handleRingSizeSelect(item.circumference)
                          }
                        >
                          {item.circumference} mm
                        </Button>
                      </Td>

                      <Td fontSize={'sm'}>{item.diameter} mm</Td>

                      <Td isNumeric>{data2[i][region]}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default RingSize;
