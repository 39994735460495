import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { materialsFromSku } from './materials';

interface Props {
  sku: string;
  locale: 'en' | 'tr';
  size?: string;
}

const RenderMaterials: React.FC<Props> = ({ locale, sku, size = '15px' }) => {
  const a = materialsFromSku(sku, locale);

  const b = Object.entries(a);

  return (
    <Box>
      {b.map((item: any, i) => {
        return (
          <Box key={i}>
            <Flex
              justifyContent={{
                base: 'flex-start',
                md: 'flex-start'
              }}
              alignItems={'center'}
              mb={2}
              fontSize={size}
            >
              <Box
                rounded={'full'}
                w={{
                  base: 2,
                  md: 3
                }}
                h={{
                  base: 2,
                  md: 3
                }}
                mr={2}
                backgroundColor={item[1]['code']}
              ></Box>
              {item[0]}: {item[1]?.locale}
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};

export default RenderMaterials;
